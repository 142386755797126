<template>
  <b-modal
      :id="modalId"
      :size="modalSize"
      hide-header
      hide-footer
      modal-class="al-modal"
      fluid
      :centered="centered"
      :no-close-on-backdrop="noClose"
      :no-close-on-esc="noClose"
  >
    <al-card :no-padding="noPadding">
      <div class="al-modal__heading" v-if="!hideHeader">
        <h3 class="al-modal__title">
          <slot name="modal-title"/>
        </h3>
        <div class="al-modal__close" @click="$bvModal.hide(modalId)">
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2L26 26M26 2L2 26" stroke="white" stroke-width="3"/>
          </svg>
        </div>
      </div>
      <div class="al-modal__body">
        <slot/>
      </div>
    </al-card>
  </b-modal>
</template>
<script>
import AlCard from "@/components/AlCard";
export default {
  name: 'AlModal',
  components: {AlCard},
  props: {
    modalId: {type: String, required: true},
    modalSize: {type: String, default: 'md'},
    centered: {type: Boolean, default: false},
    noClose: {type: Boolean, default: false},
    hideHeader: {type: Boolean, default: false},
    noPadding: {type: Boolean, default: false}
  }

}
</script>
