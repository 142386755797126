<template>
    <div class="points-container">
        <div class="point" v-for="(point, index) in length" :key="index" :style="markedPoint(index)" />
    </div>
</template>

<script>
export default {
    name: 'AlSliderIndicator',
    props: {
        currentIndex: {
            type: Number,
            required: true,
        },
        length: {
            type: Number,
            required: true,
        },
    },
    methods: {
        markedPoint(index) {
            if (index + 1 === this.currentIndex) return 'width: 2.43rem';
        },
    },
};
</script>
<style lang="scss" scoped>
.points-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.point {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 0.375rem;
    background-color: #dc4433;
    transition: width 0.5s;
    margin: 0 5px;
}
</style>
