import axios from "axios";

class EventApi {
    async getAllEvents() {
        try {
            const result = await axios.get('/events');
            return result.data;
        }catch (err) {
            console.log(err);
            alert(`Ошибка запроса ${err.message}`);
        }
    }

    async getEventById(eventId) {
        try {
            const result = await axios.get(`/events/${eventId}`);

            return [null, result.data];
        }catch (err) {
            return [err, null];
        }
    }

    async getNearestEvent() {
        try {
            const result = await axios.get('/events/nearest');
            return result.data;
        }catch (err) {
            console.log(err);
            alert(`Ошибка запроса ${err.message}`);
        }
    }

    async subscribeToEventById(eventId, eventFormat= 'online'){
        try {
            const result = await axios.get(`/events/${eventId}/subscribe?format=${eventFormat}`);
            return [null, result.data];

        }catch (err) {
            return [err, null];
        }
    }

    async unsubscribeFromEventById(eventId){
        try {
            const result = await axios.get(`/events/${eventId}/unsubscribe`);
            return [null, result.data];

        }catch (err) {
            return [err, null];
        }
    }


}

export default new EventApi();
