<template>
    <al-modal :modal-id="'event-subscribe-modal'" class="al-event-subscribe-modal" modal-size="md" centered>
        <template #modal-title>
            <div class="al-event-subscribe-modal__title">Выберите с кем отправитесь на задание:</div>
        </template>
        <div class="al-event-subscribe-modal__radio">
            <div class="radio" v-for="(type, index) in subscribeTypes" :key="`subscribe-type-${index}`">
                <input
                    type="radio"
                    class="custom-radio"
                    :name="type.value"
                    :id="`id-subscribe-type-${index}`"
                    :value="type.value"
                    v-model="eventType"
                />
                <label :for="`id-subscribe-type-${index}`">{{type.name}}</label>
            </div>
        </div>
        <div class="al-event-subscribe-modal__control">
            <al-btn size="lg" @click="subscribeToEvent">Записаться</al-btn>
        </div>
    </al-modal>
</template>

<script>
import AlModal from '@/components/AlModal';
import AlBtn from '@/components/AlBtn';
import EventApi from '@/services/event';

export default {
    name: 'AlSubscribeModal',
    components: { AlModal, AlBtn },
    props: {
        eventId: {
            type: Number,
            required: true,
        },
    },
  computed: {
    subscribeTypes(){
      return [
        {
          name: 'Альфа 0 — очная группа',
          value: 'offline'
        },
        {
          name: 'Альфа А — дистанционная группа',
          value: 'online'
        },
        {
          name: 'Альфа ► — посмотрю в записи',
          value: 'record'
        },
      ];
    },
  },
    data: () => ({
        eventType: 'offline',
    }),
    methods: {
        async subscribeToEvent() {
            const [err, result] = await EventApi.subscribeToEventById(this.eventId, this.eventType);
            if (err) {
                alert('При записи произошла ошибка, попробуйте ещё раз');
            }
            this.$bvModal.hide('event-subscribe-modal');
            this.$emit('needToUpdateEvent');
        },
    },
};
</script>
