<template>
  <div class="events-list">
    <b-container>
      <VueSlickCarousel
          v-if="events.length"
          :arrows="true"
          :dots="true"
          :slidesToShow="2"
          :slidesToScroll="1"
          @beforeChange="beforeCarouselChange"
          :autoplay="false"
          :infinite="false"
          :rows="1"

          ref="carousel"
          :responsive="[
              {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
              },

          ]"
      >

        <div class="events-list__carousel-item" v-for="event in events" :key="`event-slide-card-${event.id}`">
          <al-card class="events-list-card">
            <div class="top">
              <div class="title">{{ event.title }}</div>
              <div class="description">
                {{ event.description }}
              </div>
            </div>
            <div class="bottom">
              <div class="date-block">
                <span class="title">Дата проведения: </span>
                <span class="date"> {{ formatEventDate(event.begin_at, event.end_at) }}</span>
              </div>
              <!-- <div class="format">
          <span class="title">Формат: </span>
          <span class="value">Очно</span>
      </div> -->
              <div class="events-list__slide-footer">
                <al-btn
                    class="mb-2"
                    icon-right
                    variant="primary"
                    @click="openSubscribeModal(event.id)"
                    v-if="!event.is_subscribed && !event.isPast && !event.isEventStarting"
                    :points="event.points_subscribe"
                >
                  Записаться
                </al-btn>

                <al-btn
                    v-else-if="!event.isPast && !event.isEventStarting"
                    @click="unsubscribeFromEvent(event.id)"
                    variant="link"
                    class="announce__unsubscribe-button mb-2"
                >Отписаться</al-btn>

                <al-btn
                    class="mb-2"
                    variant="secondary"
                    @click="$router.push(`/event-online/${event.id}`)"
                    :points="event.points_to_receive ?? 0"
                ><div class="button-content">Узнать подробнее</div>
                </al-btn>
              </div>
            </div>

          </al-card>
        </div>
        <template #prevArrow="arrowOption">
          <div class="event-list__prev-arrow ">
            <al-btn variant="primary" :disabled="isPreviousButtonDisabled">
              <span class="events-list__arrow" v-if="$mq === 'xl' || $mq === 'xxl'"> Назад</span>
              <previous-icon v-else />
            </al-btn>
          </div>
        </template>

        <template #nextArrow="arrowOption">
          <div class="event-list__prev-arrow">
            <al-btn variant="primary" :disabled="isNextButtonDisabled">
              <span class="events-list__arrow" v-if="$mq === 'xl' || $mq === 'xxl'">Далее</span>
              <next-icon v-else />
            </al-btn>
          </div>
        </template>

        <template #customPaging="page">
          <div class="events-list__points">

              <div class="events-list__point"  />


          </div>
        </template>
      </VueSlickCarousel>
    </b-container>
    <al-subscribe-modal
      :eventId="selectedEventId"
      @needToUpdateEvent="fetchEvents"
    />
    </div>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import MissionApi from "@/services/mission";
import AlCard from "@/components/AlCard";
import { formatEventDate } from '@/utils/helpers';
import AlBtn from "@/components/AlBtn";
import AlBonusBadge from "@/components/AlBonusBadge";
import AlSliderIndicator from "@/components/AlSliderIndicator";
import PreviousIcon from "@/assets/images/icons/previous.svg";
import NextIcon from "@/assets/images/icons/next.svg";
import EventApi from "@/services/event";
import {parseJSON, isPast, isAfter, add} from 'date-fns';
import AlSubscribeModal from '@/components/AlSubscribeModal';

export default {
  name: 'EventsList',
  components: {AlCard, VueSlickCarousel, AlBtn, AlBonusBadge, AlSliderIndicator, PreviousIcon, NextIcon, AlSubscribeModal},
  props: {
    missionId: {type: String | Number}
  },
  data: () => ({
    events: [],
    currentPage: 0,
    selectedEventId: 0,
  }),
  computed: {
    isNextButtonDisabled(){
      return this.currentPage >= this.events.length-2;
    },

    isPreviousButtonDisabled(){
      return this.currentPage <= 0;
    }
  },

  async mounted() {
    await this.fetchEvents();
  },

  methods: {

    async fetchEvents(){
      const [err, mission] = await MissionApi.getMissionById(this.missionId);
      this.events = mission.events
          .map(event => ({
            ...event,
            begin_at: parseJSON(event.begin_at),
            end_at: parseJSON(event.end_at),
            isPast: isPast(parseJSON(event.begin_at)),
            isEventStarting: !isAfter(parseJSON(event.begin_at), add(Date.now(), {hours: 24}))
          }))
          .sort((a, b) => a.begin_at.getTime() - b.begin_at.getTime())
      ;
    },

    formatEventDate,
    beforeCarouselChange(oldPage,newPage){
      this.currentPage = newPage;
    },

    openSubscribeModal(eventId){
        this.selectedEventId = eventId;
        this.$bvModal.show('event-subscribe-modal');
    },

    async unsubscribeFromEvent (eventId) {
      const [err, result] = await EventApi.unsubscribeFromEventById(eventId);
      if (err) {
        alert('При отписке произошла ошибка, попробуйте ещё раз')
      }
      await this.fetchEvents();
    }
  }

}
</script>
